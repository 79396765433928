import { useMemo, useCallback } from 'react';
import type { PartnerPublicDataApiModel } from '@lama/partner-service-client';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { partnerServiceClient } from '../../shared/clients/partnerService';
import { getPartnerIdFromUrl } from '../usePartnerFromSubdomain';

export const usePartnerFromSubDomainQuery = (options?: UseQueryOptions<PartnerPublicDataApiModel | null>) => {
  const partnerId = useMemo(() => getPartnerIdFromUrl(window.location), []);

  const getPartnerQuery = useCallback(async () => (partnerId ? partnerServiceClient.getPartnerPublicData(partnerId) : null), [partnerId]);

  return useQuery<PartnerPublicDataApiModel | null>({
    queryKey: ['partner', partnerId],
    queryFn: getPartnerQuery,
    ...options,
  });
};
