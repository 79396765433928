import { useAsync } from 'react-use';
import { useRecoilState } from 'recoil';
import { partnerServiceClient } from '../shared/clients/partnerService';
import { partnerState } from '../state/appState';

export const isDemo = () => {
  const [subdomain] = window.location.hostname.split('.');
  return subdomain === 'demo' || subdomain.includes('localhost');
};

export const getPartnerIdFromUrl = (location: Location) => {
  const [subdomain] = window.location.hostname.split('.');

  if (location.hostname.includes('netlify')) {
    return 'testing';
  }

  if (subdomain.includes('application')) {
    return 'default';
  }

  if (isDemo()) {
    const urlParams = new URLSearchParams(window.location.search);
    const partnerId = urlParams.get('partnerId');
    return partnerId;
  }

  return subdomain;
};

export const usePartnerFromSubdomain = () => {
  const [partner, setPartner] = useRecoilState(partnerState);

  useAsync(async () => {
    if (!partner) {
      const partnerId = getPartnerIdFromUrl(window.location);
      if (!partnerId) {
        return;
      }
      const partnerEntityFromBackend = await partnerServiceClient.getPartnerPublicData(partnerId);
      if (!partnerEntityFromBackend) {
        return;
      }
      const isRunningInDemo = isDemo();
      const isPartnerProduction = partnerEntityFromBackend.status === 'production';
      if (isRunningInDemo && isPartnerProduction) {
        return;
      }
      if (!isRunningInDemo && !isPartnerProduction) {
        return;
      }

      setPartner(partnerEntityFromBackend);
    }
  }, [partner, setPartner]);

  return partner;
};
